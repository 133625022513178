import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class MediaService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/media';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getInstitutionAddress(universityId) {
        let url = `${this.#api}/institution/${universityId}`
        return apiService.get(url)
    }

    getProgramAddress(programId) {
        let url = `${this.#api}/program/${programId}`
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url)
    }

    store(data) {
        let url = `${this.#api}/save/video`
        return apiService.post(url, data)
    }
}
